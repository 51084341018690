<template>
<v-simple-table dense>
  <thead>
  <tr>
    <th class="number">Date</th>
    <th>Job</th>
    <th class="number">Job #</th>
    <th class="number">T1</th>
    <th class="number">T1.5</th>
    <th class="number">T2</th>
    <th class="number">T3</th>
    <th class="number">Own car</th>
    <th class="number">Mileage</th>
    <th class="number">Extra</th>
    <th class="approved">Approved</th>
  </tr>
  </thead>
  <tbody>
  <tr v-for="day in days" :key="day.uid"
      :class="{ unapproved: !approved[day.uid] }">
    <td>
      <router-link  class="text-decoration-none"
                    :to="{ name: 'user-calendar', params: { userSlug: slugify(day.user_name), atDate: day.start.split(/[ T]/)[0] } }">
        {{ day.date }}
      </router-link>
    </td>
    <td :title="day.id">
      {{ day.job_name }}
      <v-chip v-if="day.type === 'workshop'" color="warning" x-small class="ml-2">W</v-chip>
    </td>
    <td>{{ day.job_number }}</td>
    <td>{{ day.ot_1 }}</td>
    <td>{{ day.ot_1p5 }}</td>
    <td>{{ day.ot_2 }}</td>
    <td>{{ day.ot_3 }}</td>
    <td>{{ day.car_with_kit }}</td>
    <td>{{ day.mileage }}</td>
    <td>{{ day.extra_pay > 0 ? `£${day.extra_pay}` : '' }}</td>
    <td>
      <v-simple-checkbox :value="approved[day.uid]"
                         @input="approvedChanged(day, $event)" :ripple="false">
      </v-simple-checkbox>
    </td>
  </tr>
  </tbody>
</v-simple-table>
</template>

<script>
import * as api from '../api'

export default {
  name: 'OvertimeTable',
  props: ['days'],
  data() {
    return {
      approved: {}
    }
  },
  watch: {
    days() {
      this.syncApproved()
    }
  },
  methods: {
    syncApproved() {
      const approved = {}
      for (const day of this.days) {
        approved[day.uid] = day.approved
      }
      this.approved = approved
    },
    async approvedChanged(day, approved) {
      this.$set(this.approved, day.uid, approved)
      this.$forceUpdate() // not sure why we need this
      await api.put('/shift/approved', { shift_type: day.type, shift_id: day.id, approved })
    }
  },
  created() {
    this.syncApproved()
  }
}
</script>

<style scoped>
th.approved {
  width: 10px;
}
th.number {
  width: 8%;
}
tr.unapproved {
  opacity: 0.5;
}
</style>
