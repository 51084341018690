<template>
<page-wrapper>
  <v-row dense>
    <v-col cols="2">
      <v-select v-model="month" :items="availableMonths" label="Month" @change="fetchReport"></v-select>
    </v-col>
    <v-spacer></v-spacer>
    <right-hand-buttons>
      <mileage-report :month="month"></mileage-report>
      <file-download end-point="/staff-ot/report" :params="{ month }">
        Payroll
      </file-download>
    </right-hand-buttons>
  </v-row>
  <section v-for="[user, days] in report" :key="user">
    <h3 class="ml-2">{{ user }}</h3>
    <overtime-table :days="days"></overtime-table>
  </section>
</page-wrapper>
</template>

<script>
import PageWrapper from '../components/ui/PageWrapper'
import * as api from '../api'
import OvertimeTable from '../components/OvertimeTable'
import FileDownload from '../components/export/FileDownload'
import MileageReport from '../components/MileageReport'
import RightHandButtons from '../components/ui/RightHandButtons'

export default {
  name: 'StaffOT',
  components: {
    RightHandButtons,
    MileageReport,
    FileDownload,
    OvertimeTable,
    PageWrapper
  },
  data() {
    return {
      month: null,
      report: [],
      availableMonths: []
    }
  },
  methods: {
    async fetchReport() {
      this.$router.push({
        name: this.$route.name,
        query: {
          month: this.month
        }
      }).catch(e => {})
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const { query } = to
    if (!query.month) {
      query.month = this.availableMonths[0].value
    }
    this.report = await api.get('/staff-ot', query)
    this.month = query.month
    next()
  },
  async mounted() {
    this.availableMonths = await api.get('/shoot/months')
    if ('month' in this.$route.query) {
      const { month } = this.$route.query
      this.month = month
    } else {
      this.month = this.availableMonths[0].value
    }
    const query = { month: this.month }
    this.report = await api.get('/staff-ot', query)
  }
}
</script>

<style scoped lang="scss">
section {
  margin-bottom: 2rem;
}
</style>
